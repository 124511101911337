<template>
	<div v-if="loaded">
		<v-row dense>
			<v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
				<v-row dense>
					<v-col cols="12" xl="12" lg="12" class="pr-2 mt-2">
						<el-input
							placeholder="Buscar..."
							v-model="input_item"
							@input="searchItems"
							autofocus
						></el-input>
					</v-col>
					<v-col cols="12" xl="12" lg="12" class="pr-2">
						<v-tabs
							fixed-tabs
							background-color="primary"
							class="rounded"
							centered
							dark
						>
							<v-tab @click="showAllCategories()">
								CATEGORÍAS
							</v-tab>
							<v-tab @click="showAllItems()">
								TODOS
							</v-tab>
							<v-tab @click="getFavoriteItems()">
								FAVORITOS
							</v-tab>
						</v-tabs>
					</v-col>
				</v-row>
				<emb-perfect-scrollbar class="scroll-area" style="height: 77vh;">
					<template v-if="!showAllItem">
						<v-row dense no-gutters>
							<v-col
								cols="12"
								xl="3"
								lg="4"
								md="4"
								sm="6"
								v-for="(category, index) in categories"
								:key="index"
								class="pr-1 pb-1 mb-0"
							>
								<v-card
									@click="searchItemByCategory(category.id)"
									color="secondary"
									pointer
									dark
									class="text-center"
								>
									<v-img
										class="white--text align-center"
										height="85px"
									>
										<p
											class="text-center ma-1"
										>
											{{ category.name }}
										</p>
									</v-img>

									<!-- <p class="pa-10 white--text align-center">

									</p> -->
								</v-card>
							</v-col>
						</v-row>
					</template>
					<template v-else>
						<v-row dense>
							<v-col
								cols="12"
								xl="2"
								lg="2"
								md="3"
								sm="6"
								v-for="(item, i) in items"
								:key="i"
								class="pr-2 d-flex align-items-stretch"
							>
								<v-card hover color="secondary" @click="clickAddItem(item, i)">
									<v-img
										:src="item.image"
										class="white--text align-center"
										gradient="to bottom, rgba(0,0,0,.8), rgba(0,0,0,.9)"
										height="140px"
									>
										<p
											class="text-center font-weight-medium ma-1"
										>
											<small>{{ item.full_name }}</small>

										</p>
									</v-img>

									<!-- <p class="text-center caption mt-3">
										{{
											'NO TIENE DESCRIPCIÓN'
										}}
									</p> -->

									<v-card-actions>
										<v-spacer></v-spacer>
										<h6 class="mb-0">
											{{ item.currency_type_symbol }}
											{{ item.sale_unit_price }}
										</h6>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</template>
				</emb-perfect-scrollbar>
			</v-col>
			<v-col
				cols="12"
				xl="4"
				lg="4"
				md="4"
				sm="12"
				xs="12"
				style="height: calc(100vh - 65px);"
				class="secondary mt-1 pt-2"
			>
				<div class="h-80" style="overflow-y: auto; overflow-x: hidden;">
					<v-row dense class="m-0 p-0">
						<v-col
							cols="12"
							:xl="!command ? '6' : '12'"
							:lg="!command ? '6' : '12'"
							:md="!command ? '6' : '12'"
							sm="12"
							class="p-0"
						>
							<el-select
								v-model="form.table_id"
								dusk="table_id"
								:disabled="!change_table"
								filterable
							>
								<el-option
									v-for="option in tables"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<div class="text-center">
								<el-checkbox class="mt-1" v-model="change_table"
									>Cambiar Mesa</el-checkbox
								>
							</div>
						</v-col>
						<v-col cols="12" xl="6" lg="6" md="6" sm="12" class="p-0" v-if="!command">
							<el-input
								v-model="form.waiter_code"
								placeholder="Cod. Usuario"
							></el-input>
							<div class="text-center">
								<el-checkbox class="mt-1" v-model="form.consumption"
									>Consumo</el-checkbox
								>
							</div>
						</v-col>
					</v-row>
					<v-row dense v-if="form.consumption">
						<v-col cols="12">
							<el-input v-model="form.concept" placeholder="Concepto"></el-input>
							<small
								class="error--text"
								v-if="errors.concept"
								v-text="errors.concept[0]"
							></small>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="12" md="12" class="p-0">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Cant.</th>
											<th class="text-left">Producto</th>
											<th class="text-left">Precio</th>
											<th class="text-left">Acc.</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in form.items"
											:key="index"
											class="hover"
										>
											<template v-if="currentIndex != index">
												<td width="20%" class="pr-1 pl-0">
													<el-input
														v-model="item.item.aux_quantity"
														size="mini"
														@input="clickAddItem(item, index, true)"
														class="input-text-right"
													></el-input>
												</td>
												<td width="50%" class="pa-0">
													<template
														v-if="item.item.full_name.length > 30"
													>
														<el-tooltip
															class="item"
															effect="dark"
															:content="item.item.full_name"
															placement="top"
														>
															<p>{{ item.item.name }}</p>
														</el-tooltip>
													</template>
													<template v-else>
														<p class="mb-0">
															{{ item.item.full_name }}
														</p>
													</template>
													<!-- <strong class="mb-0">{{ currency_type.symbol }} {{ item.total }}</strong> -->
												</td>
												<td width="20%" class="pl-0 pr-1">
													<!-- <el-input
														v-model="item.unit_price"
														size="mini"
														@input="calculateRowTotal(item.item)"
														class="input-text-right"
													></el-input> -->
													<el-input
														v-model="item.unit_price"
														size="mini"
														@input="calculateRowTotal(item.item)"
														class="input-text-right"
														:readonly="permission ? false : true"
													></el-input>
												</td>
												<td class="text-right align-middle pa-0">
													<v-btn
														fab
														dark
														x-small
														color="accent"
														@click="getCurrentIndex(index)"
													>
														<v-icon dark>mdi-settings</v-icon>
													</v-btn>
												</td>
											</template>
											<template v-else>
												<td
													colspan="3"
													class="text-right align-middle pl-0 pr-1"
												>
													<el-input
														v-model="item.note"
														size="mini"
														placeholder="Nota para el chef"
													></el-input>
												</td>
												<td class="text-right align-middle pa-0">
													<v-btn
														class="mr-1"
														fab
														dark
														x-small
														color="error"
														v-if="!item.id || permission"
														@click="clickDeleteItem(index)"
													>
														<v-icon dark>mdi-delete</v-icon>
													</v-btn>
													<v-btn
														fab
														dark
														x-small
														color="success"
														@click="clearCurrentIndex"
													>
														<v-icon dark>mdi-check-all</v-icon>
													</v-btn>
												</td>
											</template>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</div>
				<v-divider></v-divider>
				<div class="h-20 white--text" style="overflow-y: auto; overflow-x: hidden;">
					<div class="m-0 p-0 ">
						<v-row dense>
							<v-col cols="12" xl="6" lg="6" md="6" sm="6">
								<h4>TOTAL:</h4>
							</v-col>
							<v-col cols="12" xl="6" lg="6" md="6" sm="6">
								<h4 class="text-right">
									{{ currency_type.symbol }} {{ form.total }}
								</h4>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" xl="6" lg="6" md="6">
								<v-btn
									block
									outlined
									color="error"
									class="white--text"
									@click="backTo()"
								>
									<v-icon left dark>mdi-chevron-left</v-icon>
									Cancelar
								</v-btn>
							</v-col>
							<v-col cols="12" xl="6" lg="6" md="6">
								<v-btn
									color="accent"
									block
									:disabled="form.total <= 0"
									@click.prevent="submit"
									:loading="loading_submit"
								>
									Ordenar (F8)
									<v-icon right dark>mdi-send</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { functions, exchangeRate } from 'Mixins/functions';
import { calculateRowItem } from 'Helpers/functions';
import { deletable } from 'Mixins/deletable';
import filter from 'lodash/filter';
import round from 'lodash/round';
import find from 'lodash/find';
import dayjs from 'dayjs';
// import PaymentForm from './partials/payment';

export default {
	// components: {PaymentForm},
	mixins: [functions, exchangeRate, deletable],
	data() {
		return {
			resource: 'commands',
			loading_submit: false,
			loaded: false,
			loading_search: false,
			input_item: null,
			payment: false,
			recordId: null,
			affectation_igv_types: [],
			form_item: {},
			row: {},
			errors: {},
			form: {},
			items: [],
			warehouses: [],
			all_items: [],
			categories: [],
			establishment: {},
			currency_type: {},
			currency_types: [],
			orderNewId: null,
			change_table: false,
			show_all_items: false,
			deleted_item: false,
			categoryId: null,
			currentIndex: null,
			table: null,
			tables: [],
			command: null,
			permission: false,
			activeFavorite: false,
			showAllItem: false,
		};
	},
	async created() {
		await this.getDynamicTables();
		await this.getStaticTables();

		this.currentIndex = null;
	},
	async mounted() {
		await window.addEventListener(
			"keyup",
			function(e) {
				if (e.which == 119) {
					this.submit()
				}
			}.bind(this)
		);
	},
	methods: {
		async initForm() {
			this.activeFavorite = false;
			this.showAllItem = false;
			this.errors = {};
			if (this.command) {
				this.form = this.command;
			} else {
				this.form = {
					id: null,
					prefix: 'PE01',
					waiter_code: null,
					establishment_id: null,
					warehouse_id: null,
					establishment: {},
					date_of_issue: dayjs().format('YYYY-MM-DD'),
					time_of_issue: dayjs().format('HH:mm:ss'),
					date_of_due: dayjs().format('YYYY-MM-DD'),
					table_id: this.$route.params.id,
					is_active: true,
					paid: false,
					customer_id: null,
					currency_type_id: 'PEN',
					payment_condition_type_id: '1',
					purchase_order: null,
					exchange_rate_sale: 0,
					total_prepayment: 0,
					total_charge: 0,
					total_discount: 0,
					total_exportation: 0,
					total_free: 0,
					total_taxed: 0,
					total_unaffected: 0,
					total_exonerated: 0,
					total_igv: 0,
					total_base_isc: 0,
					total_isc: 0,
					total_base_other_taxes: 0,
					total_other_taxes: 0,
					total_taxes: 0,
					total_value: 0,
					total: 0,
					operation_type_id: '0101',
					items: [],
					consumption: false,
					consumption_confirmed: false,
					concept: null,
					discount: {
						discount_type_id: '02',
						description: 'Descuento Global afecta a la base imponible',
						factor: 0,
						amount: 0,
						base: 0,
					},
				};
				this.initFormItem();
			}
		},
		async getDynamicTables() {
			localStorage.removeItem('items');
			localStorage.removeItem('categories');
			let getCagories = localStorage.getItem('categories') ? 0 : 1;
			let getItems = localStorage.getItem('items') ? 0 : 1;
			let params = `getCagories=${getCagories}&getItems=${getItems}&table_id=${this.$route.params.id}&command_id=${this.$route.params.command_id}`;
			await this.$http
				.get(`/${this.resource}/dynamic-tables/?${params}`)
				.then((response) => {
					this.table = response.data.table;
					this.tables = response.data.tables;
					this.command = response.data.command;

					this.establishment = response.data.establishment;
					this.warehouses = response.data.warehouses;

					if (response.data.items.length > 0) {
						this.items = response.data.items;
						localStorage.setItem('items', JSON.stringify(this.items));
					} else {
						this.items = JSON.parse(localStorage.getItem('items'));
					}

					if (response.data.categories.length > 0) {
						this.categories = response.data.categories;
						localStorage.setItem('categories', JSON.stringify(this.categories));
					} else {
						this.categories = JSON.parse(localStorage.getItem('categories'));
					}
					this.permission = response.data.permission;

					this.initForm();
					this.calculateTotal();

					this.form.establishment_id = this.establishment.id;
					this.form.establishment = this.establishment;

					this.form.warehouse_id =
						this.warehouses.length > 0 ? this.warehouses[0].id : null;

					this.loaded = true;
				});
		},
		async getStaticTables() {
			await this.$http.get(`/${this.resource}/static-tables`).then((resp) => {
				this.affectation_igv_types = resp.data.affectation_igv_types;
				this.currency_types = resp.data.currency_types;

				this.form.currency_type_id =
					this.currency_types.length > 0 ? this.currency_types[0].id : null;

				this.changeCurrencyType();
			});
		},
		searchItems() {
			if (this.input_item.length > 2) {
				let inputFmt = this.input_item.toUpperCase();
				if (this.activeFavorite == true) {
					if (localStorage.getItem('favorites')) {
						const favorites = JSON.parse(localStorage.getItem('favorites'));

						this.items = favorites.filter((favorite) => {
							return favorite.name.toUpperCase().indexOf(inputFmt) > -1;
						});
						this.showAllItem = true;
					}
				} else {
					const items = JSON.parse(localStorage.getItem('items'));

					this.items = items.filter((favorite) => {
						return favorite.name.toUpperCase().indexOf(inputFmt) > -1;
					});
					this.showAllItem = true;
					// let parameters = `input=${this.input_item}`;
					// this.$http
					// 	.get(`/${this.resource}/search/items?${parameters}`)
					// 	.then((response) => {
					// 		this.items = response.data;
					// 	});
				}
			} else {
				this.showAllItem = false;
				this.items = [];
			}
		},
		showAllCategories() {
			this.activeFavorite = false;
			this.showAllItem = false;
			this.items = [];
		},
		showAllItems() {
			this.showAllItem = true;
			this.activeFavorite = false;
			this.items = JSON.parse(localStorage.getItem('items'));
		},
		async getFavoriteItems() {
			if (localStorage.getItem('favorites')) {
				this.items = JSON.parse(localStorage.getItem('favorites'));
				this.activeFavorite = true;
				this.showAllItem = true;
			} else {
				await this.$http.get(`/${this.resource}/search/item-favorites`).then((response) => {
					this.items = response.data;
					if (this.items.length > 0) {
						localStorage.setItem('favorites', JSON.stringify(this.items));
						this.activeFavorite = true;
						this.showAllItem = true;
					}
				});
			}
		},
		async searchItemByCategory(categoryId) {
			let items = JSON.parse(localStorage.getItem('items'));
			this.items = filter(items, { category_id: categoryId });
			if (this.items.length > 0) {
				this.showAllItem = true;
			}
			// await this.$http
			// 	.get(`/${this.resource}/search/item/by-category/${categoryId}`)
			// 	.then((response) => {
			// 		this.items = response.data;
			// 	});
		},
		async clickAddItem(item, index, input = false) {
			let exchangeRateSale = this.form.exchange_rate_sale;
			let exist_item = find(this.form.items, { item_id: item.item_id });
			let pos = this.form.items.indexOf(exist_item);

			if (exist_item) {
				if (input) {
					exist_item.quantity = exist_item.item.aux_quantity;
				} else {
					exist_item.quantity++;
					exist_item.item.aux_quantity++;
				}

				this.row = calculateRowItem(
					exist_item,
					this.form.currency_type_id,
					exchangeRateSale
				);
				this.form.items[pos] = this.row;
			} else {
				this.form_item.item = item;
				this.form_item.id = this.form_item.id ? this.form_item.id : null;
				this.form_item.unit_price_value = this.form_item.item.sale_unit_price;
				this.form_item.has_igv = this.form_item.item.has_igv;
				this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
				this.form_item.quantity = 1;
				this.form_item.aux_quantity = 1;

				let unit_price = this.form_item.has_igv
					? this.form_item.unit_price_value
					: this.form_item.unit_price_value * 1.10;

				this.form_item.unit_price = unit_price;
				this.form_item.item.unit_price = unit_price;
				this.form_item.item.presentation = null;

				this.form_item.charges = [];
				this.form_item.attributes = [];
				this.form_item.affectation_igv_type = find(this.affectation_igv_types, {
					id: this.form_item.affectation_igv_type_id,
				});

				this.row = calculateRowItem(
					this.form_item,
					this.form.currency_type_id,
					exchangeRateSale
				);

				this.form.items.push(this.row);
				item.aux_quantity = 1;
			}

			if (!input) {
				this.$message.success('Producto agregado correctamente!');
			}

			this.calculateTotal();
		},
		changeDateOfIssue() {
			this.searchExchangeRateByDate(this.form.date_of_issue).then((response) => {
				this.form.exchange_rate_sale = response;
			});
		},
		clickDeleteItem(index) {
			if (this.form.items[index].id != null) {
				this.remove(`/${this.resource}/order_item/${this.form.items[index].id}`).then(
					() => {
						this.form.items.splice(index, 1);
						this.deleted_item = true;
						this.calculateTotal();
						this.submit();
						this.currentIndex = null;
					}
				);
			} else {
				this.form.items.splice(index, 1);
				this.calculateTotal();
				this.currentIndex = null;
			}
		},
		changeCurrencyType() {
			this.currency_type = find(this.currency_types, { id: this.form.currency_type_id });
		},
		calculateRowTotal(item) {
			let row = find(this.form.items, { item_id: item.item_id });
			row.item.unit_price = parseFloat(row.unit_price);

			let currency_type_id_new = this.form.currency_type_id;
			let exchange_rate_sale = this.form.exchange_rate_sale;
			let currency_type_id_old = row.item.currency_type_id;
			let unit_price = parseFloat(row.item.unit_price);

			if (currency_type_id_old === 'PEN' && currency_type_id_old !== currency_type_id_new) {
				unit_price = unit_price / exchange_rate_sale;
			}

			if (currency_type_id_new === 'PEN' && currency_type_id_old !== currency_type_id_new) {
				unit_price = unit_price * exchange_rate_sale;
			}

			let percentage_igv = 10;
			let unit_value = parseFloat(row.unit_price);

			if (row.affectation_igv_type_id === '10') {
				unit_value = row.unit_price / (1 + percentage_igv / 100);
			}

			console.log(unit_price);

			// row.unit_value = round(unit_value, 4)

			row.unit_value = parseFloat(unit_value);

			let total_value_partial = unit_value * row.quantity;

			/* Discounts */
			let discount_base = 0;
			let discount_no_base = 0;

			/* Charges */
			let charge_base = 0;
			let charge_no_base = 0;

			let total_isc = 0;
			let total_other_taxes = 0;

			let total_discount = discount_base + discount_no_base;
			let total_charge = charge_base + charge_no_base;
			let total_value = total_value_partial - total_discount + total_charge;
			let total_base_igv = total_value_partial - discount_base + total_isc;

			let total_igv = 0;

			if (row.affectation_igv_type_id === '10') {
				total_igv = (total_base_igv * percentage_igv) / 100;
			}
			if (row.affectation_igv_type_id === '20') {
				//Exonerated
				total_igv = 0;
			}
			if (row.affectation_igv_type_id === '30') {
				//Unaffected
				total_igv = 0;
			}

			let total_taxes = total_igv + total_isc + total_other_taxes;
			let total = total_value + total_taxes;

			row.total_charge = total_charge;
			row.total_discount = total_discount;
			// row.total_charge = total_charge
			row.total_value = total_value;
			row.total_base_igv = total_base_igv;
			row.total_igv = total_igv;
			row.total_taxes = total_taxes;
			row.total = round(total, 2);

			// descomentar para op. gratuitas
			// if (row.affectation_igv_type.free) {
			//     row.price_type_id = '02'
			//     row.unit_value = 0
			//     // row.total_value = 0
			//     row.total = 0
			// }

			this.calculateTotal();
		},
		calculateTotal() {
			let total_discount = 0;
			let total_charge = 0;
			let total_exportation = 0;
			let total_taxed = 0;
			let total_exonerated = 0;
			let total_unaffected = 0;
			let total_free = 0;
			let total_igv = 0;
			let total_value = 0;
			let total = 0;
			this.form.items.forEach((row) => {
				total_discount += parseFloat(row.total_discount);
				total_charge += parseFloat(row.total_charge);

				if (row.affectation_igv_type_id === '10') {
					total_taxed += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '20') {
					total_exonerated += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '30') {
					total_unaffected += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '40') {
					total_exportation += parseFloat(row.total_value);
				}
				if (['10', '20', '30', '40'].indexOf(row.affectation_igv_type_id) < 0) {
					total_free += parseFloat(row.total_value);
				}
				if (['10', '20', '30', '40'].indexOf(row.affectation_igv_type_id) > -1) {
					total_igv += parseFloat(row.total_igv);
					total += parseFloat(row.total);
				}
				total_value += parseFloat(row.total_value);
			});

			this.form.total_exportation = round(total_exportation, 2);
			this.form.total_discount = round(total_discount, 2);
			this.form.total_charge = round(total_charge, 2);
			this.form.total_taxed = round(total_taxed, 2);
			this.form.total_exonerated = round(total_exonerated, 2);
			this.form.total_unaffected = round(total_unaffected, 2);
			this.form.total_free = round(total_free, 2);
			this.form.total_igv = round(total_igv, 2);
			this.form.total_value = round(total_value, 2);
			this.form.total_taxes = round(total_igv, 2);
			this.form.total = round(total, 2);
		},
		submit() {
			if (!this.form.waiter_code && !this.command)
				return this.$message.error('Para continuar debe ingresar su cód. de usuario');

			let exist = this.form.items.find((item) => Number(item.quantity) == 0)
			if (exist) {
				return this.$message.error('Error la cantidad de los productos agregados no puede ser cero');
			}

			if (!this.form.id) {
				this.form.time_of_issue = dayjs().format('HH:mm:ss')
			}

			this.loading_submit = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.form.id = response.data.id;
						this.form.items = response.data.items;

						if (!this.deleted_item) {
							let zones = response.data.zones;
							// let pc_ip = response.data.pc_ip;
							for (let index = 0; index < zones.length; ++index) {
								let data = JSON.stringify(zones[index]);

								let url = `https://print-api.test/print/command`;
								// let url = `https://${pc_ip}/print-api/print/command`;

								fetch(url, { method: 'POST', body: data })
									.then((dataWrappedByPromise) => dataWrappedByPromise.json())
									.then((data) => {
										if (data.success) {
											this.$message.success(data.message);
										} else {
											this.$message.error(data.message);
										}
									})
									.catch((error) => {
										console.error('Error: ', error);
									});
							}
							this.backTo();
						} else {
							this.deleted_item = false;
						}
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading_submit = false;
				});
		},
		backTo() {
			this.$router.push({ name: 'SaleIndex' });
		},
		getCurrentIndex(index) {
			this.currentIndex = index;
		},
		clearCurrentIndex() {
			this.currentIndex = null;
		},
		initFormItem() {
			this.form_item = {
				item_id: null,
				item: {},
				affectation_igv_type_id: null,
				affectation_igv_type: {},
				has_isc: false,
				system_isc_type_id: null,
				percentage_isc: 0,
				suggested_price: 0,
				quantity: 1,
				aux_quantity: 1,
				unit_price_value: 0,
				unit_price: 0,
				charges: [],
				discount: {
					discount_type_id: '02',
					discount_type: { base: true },
					description: 'Descuento que afecta la base imponible del IGV',
					percentage: 0,
					factor: 0,
					amount: 0,
					base: 0,
					is_amount: true,
				},
				attributes: [],
				has_igv: null,
				printed: false,
				note: null,
				item_unit_type_id: null,
			};
		},
	},
};
</script>
